.landingpage__text {
  display: flex;
  align-items: center;
  justify-items: center;
}

.landingpage__first__text {
  box-shadow: 5px 5px;
  top: 50%;
  left: 50%;
  margin-top: 30px;
  background-color: #fed7d7;
  color: #e53e3e;
  font-weight: bold;
  font-size: x-large;
  padding: 10px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.landingpage__end__text {
  box-shadow: 5px 5px;
  top: 50%;
  left: 50%;
  margin-top: 30px;
  background-color: #fed7d7;
  color: #e53e3e;
  font-weight: bold;
  font-size: x-large;
  padding: 10px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.landingpage__center__text {
  box-shadow: 5px 5px #e53e3e;
  top: 50%;
  left: 50%;
  margin-top: 30px;
  background-color: #fed7d7;
  color: #2d3748;
  font-weight: bold;
  font-size: x-large;
  padding: 10px;
}

@media only screen and (max-width: 375px) {
  .landingpage__text {
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 10px;
  }

  .landingpage__first__text {
    box-shadow: 5px 5px;
    top: 50%;
    left: 50%;
    background-color: #fed7d7;
    color: #e53e3e;
    font-size: x-small;
    font-weight: bold;
    padding: 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .landingpage__end__text {
    box-shadow: 5px 5px;
    top: 50%;
    left: 50%;
    margin-top: 30px;
    background-color: #fed7d7;
    color: #e53e3e;
    font-weight: bold;
    font-size: x-small;
    padding: 10px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .landingpage__center__text {
    box-shadow: 5px 5px #e53e3e;
    top: 50%;
    left: 50%;
    background-color: #fed7d7;
    margin-top: 30px;
    color: #2d3748;
    font-weight: bold;
    font-size: x-small;
    padding: 10px;
  }
}

@media only screen and (max-width: 951px) {
  .landingpage__text {
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 10px;
  }

  .landingpage__first__text {
    box-shadow: 5px 5px;
    top: 50%;
    left: 50%;
    background-color: #fed7d7;
    color: #e53e3e;
    font-size: medium;
    font-weight: bold;
    padding: 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .landingpage__end__text {
    box-shadow: 5px 5px;
    top: 50%;
    left: 50%;
    margin-top: 30px;
    background-color: #fed7d7;
    color: #e53e3e;
    font-weight: bold;
    font-size: medium;
    padding: 10px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .landingpage__center__text {
    box-shadow: 5px 5px #e53e3e;
    top: 50%;
    left: 50%;
    background-color: #fed7d7;
    margin-top: 30px;
    color: #2d3748;
    font-weight: bold;
    font-size: medium;
    padding: 10px;
  }
}

@media only screen and (max-width: 676px) {
  .landingpage__text {
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 10px;
  }

  .landingpage__first__text {
    box-shadow: 5px 5px;
    top: 50%;
    left: 50%;
    background-color: #fed7d7;
    color: #e53e3e;
    font-size: small;
    font-weight: bold;
    padding: 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .landingpage__end__text {
    box-shadow: 5px 5px;
    top: 50%;
    left: 50%;
    margin-top: 30px;
    background-color: #fed7d7;
    color: #e53e3e;
    font-weight: bold;
    font-size: small;
    padding: 10px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .landingpage__center__text {
    box-shadow: 5px 5px #e53e3e;
    top: 50%;
    left: 50%;
    background-color: #fed7d7;
    margin-top: 30px;
    color: #2d3748;
    font-weight: bold;
    font-size: small;
    padding: 10px;
  }
}

.my__button {
  box-shadow: 5px 5px;
}

.image__upload {
  font-size: small;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fc8181;
  padding: 5px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.register-a-space {
  background: rgb(208, 33, 33);
  background: linear-gradient(
    151deg,
    rgba(208, 33, 33, 1) 0%,
    rgba(205, 187, 187, 1) 100%
  );
}

.register-space-input {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  outline: none;
}

.generate-access-key {
  background: rgb(208, 33, 33);
  background: linear-gradient(
    151deg,
    rgba(208, 33, 33, 1) 0%,
    rgba(205, 187, 187, 1) 100%
  );
}