* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer-container {
  background-color: #111;
  color: #fff;
  height: auto;
  width: 100vw;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding-top: 20px;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.footer-content h3 {
  font-size: 1.8rem;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 3rem;
}

.footer-content p {
  max-width: 500px;
  margin: 10px auto;
  line-height: 28px;
  font-size: 14px;
}

.socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 3rem 0;
}

.socials li {
  margin: 0 10px;
  font-size: 1.1rem;
}

.footer-bottom {
  background-color: #f56565;
  /* background-color: blue; */
  width: 100vw;
  padding: 10px 0;
  text-align: center;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 400px) {
  .footer-bottom p {
    font-size: 12px;
    word-spacing: 2px;
    text-transform: capitalize;
  }
}

@media only screen and (min-width: 401px) {
  .footer-bottom p {
    font-size: 14px;
    word-spacing: 2px;
    text-transform: capitalize;
  }
}

.footer-bottom span {
  text-transform: uppercase;
  opacity: 0.4;
  font-weight: 200;
}

.privacy__policy {
  text-decoration: underline;
  margin-left: 20px;
  cursor: pointer;
}

/* a git commit */